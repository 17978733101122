import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import {useDocTitle} from '../components/CustomHook';

const Contact = () => {
    useDocTitle('Global Wise Solutions')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    // NUEVA FUNCIÓN sin cambiar textos ni diseño:
    const sendEmail = (e) => {
        e.preventDefault();
        // Deshabilita temporalmente el botón
        const submitBtn = document.getElementById('submitBtn');
        submitBtn.disabled = true;
        submitBtn.innerHTML = 'Loading...';

        // Formatea el cuerpo del correo
        const mailSubject = encodeURIComponent('Mensaje desde Global Wise Solutions');
        // Usamos %0D%0A para saltos de línea en la URL
        const mailBody = encodeURIComponent(
            `First Name: ${firstName}
Last Name: ${lastName}
Email: ${email}
Phone: ${phone}
Message: ${message}`
        );

        // Construimos el mailto
        const mailtoUrl = `mailto:info@globalwsolutions.com?subject=${mailSubject}&body=${mailBody}`;

        // Abrimos el cliente de correo
        window.location.href = mailtoUrl;

        // Volvemos a habilitar el botón y restaurar su texto (por si el usuario regresa)
        submitBtn.disabled = false;
        submitBtn.innerHTML = 'send message';

        // Limpiamos el formulario
        clearInput();
    }

    return (
        <>
            <div>
                <NavBar />
            </div>
            <div id='contact' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">

                    <form onSubmit={sendEmail}>

                        <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                            <div className="flex">
                                <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">
                                    Send us a message
                                </h1>
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                <div>
                                    <input 
                                        name="first_name" 
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text" 
                                        placeholder="First Name*" 
                                        value={firstName}
                                        onChange={(e)=> setFirstName(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors && 
                                        <p className="text-red-500 text-sm">{errors.first_name}</p>
                                    }
                                </div>
                                
                                <div>
                                    <input 
                                        name="last_name" 
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text" 
                                        placeholder="Last Name*"
                                        value={lastName}
                                        onChange={(e)=> setLastName(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors && 
                                        <p className="text-red-500 text-sm">{errors.last_name}</p>
                                    }
                                </div>

                                <div>
                                    <input 
                                        name="email"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="email" 
                                        placeholder="Email*"
                                        value={email}
                                        onChange={(e)=> setEmail(e.target.value)}
                                        onKeyUp={clearErrors}   
                                    />
                                    {errors && 
                                        <p className="text-red-500 text-sm">{errors.email}</p>
                                    }
                                </div>

                                <div>
                                    <input
                                        name="phone_number" 
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="number" 
                                        placeholder="Phone*"
                                        value={phone}
                                        onChange={(e)=> setPhone(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors && 
                                        <p className="text-red-500 text-sm">{errors.phone_number}</p>
                                    }
                                </div>
                            </div>

                            <div className="my-4">
                                <textarea 
                                    name="message" 
                                    placeholder="Message*" 
                                    className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    value={message}
                                    onChange={(e)=> setMessage(e.target.value)}
                                    onKeyUp={clearErrors}
                                ></textarea>
                                {errors && 
                                    <p className="text-red-500 text-sm">{errors.message}</p>
                                }
                            </div>

                            <div className="my-2 w-1/2 lg:w-2/4">
                                <button
                                    type="submit"
                                    id="submitBtn"
                                    className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                                        focus:outline-none focus:shadow-outline"
                                >
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </form>

                    <div
                        className="w-full lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-blue-900 rounded-2xl">
                        <div className="flex flex-col text-white">
                            
                            <div className="flex my-4 w-2/3 lg:w-3/4">
                                <div className="flex flex-col">
                                    <i className="fas fa-map-marker-alt pt-2 pr-2" />
                                </div>
                                <div className="flex flex-col">
                                    <h2 className="text-2xl">Mailing Address</h2>
                                    <p className="text-gray-400">Global Wise Solutions LLC</p>
                                    <p className="text-gray-400">99 Wall St #2315</p>
                                    <p className="text-gray-400">New York, NY 10005-4301</p>
                                </div>
                            </div>

                            <div>
                                <h2 className="text-2xl">E-mail Us at</h2>
                                <p className="text-gray-400">info@globalwsolutions.com</p>
                            </div>
                    
                            <div className="flex flex-col mt-5">
    <h2 className="text-2xl">Whatsapp</h2>
    <a
        href="https://wa.me/59177956838"
        className="mt-2 flex items-center bg-green-500 text-white py-3 px-6 rounded-full shadow-md hover:bg-green-600 transition-all duration-300"
        target="_blank"
        rel="noopener noreferrer"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            className="w-6 h-6 mr-3"
            fill="currentColor"
        >
            <path d="M16 2.959a12.948 12.948 0 0 0-12.961 12.959c0 2.263.594 4.462 1.723 6.387l-2.277 8.333 8.537-2.232a12.905 12.905 0 0 0 4.978.987h.002c7.159 0 12.961-5.803 12.961-12.961 0-7.157-5.802-12.959-12.963-12.959zm0 23.54a10.7 10.7 0 0 1-4.907-1.224l-.352-.186-5.068 1.327 1.35-4.951-.229-.354a10.696 10.696 0 0 1-1.646-5.787c0-5.945 4.845-10.789 10.79-10.789 5.946 0 10.789 4.843 10.789 10.789-.001 5.945-4.846 10.788-10.787 10.788zm5.798-8.092c-.317-.158-1.877-.924-2.168-1.031-.291-.108-.503-.158-.715.158-.208.316-.822 1.031-1.007 1.243-.183.208-.367.235-.685.08-.317-.158-1.337-.492-2.544-1.57-.941-.838-1.577-1.873-1.762-2.19-.184-.316-.02-.487.138-.645.142-.141.316-.367.474-.55.157-.184.21-.316.316-.528.104-.211.052-.396-.026-.55-.079-.158-.715-1.73-.981-2.376-.258-.624-.52-.536-.715-.547l-.606-.011c-.211 0-.55.08-.837.396s-1.1 1.076-1.1 2.62 1.127 3.037 1.285 3.246c.158.208 2.228 3.384 5.399 4.745.756.327 1.348.522 1.808.666.76.242 1.453.208 2.002.127.611-.092 1.877-.767 2.14-1.508.262-.741.262-1.374.184-1.508-.078-.133-.29-.208-.606-.367z" />
        </svg>
        <span className="text-lg font-medium">+591 77956838</span>
    </a>
</div>

                    
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact;
